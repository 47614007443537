import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, CircularProgress } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';


const App = () => {
  const [clickId, setClickId] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [title, setTitle] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [loading, setLoading] = useState(true);
  const [allDone, setAllDone] = useState(false);
  const params = new URLSearchParams(window.location.search);

  const fetchData = async () => {
      const response = await fetch('https://goqeprn8qh.execute-api.eu-west-1.amazonaws.com/Gf5RxTjMkxhwBSSCDqgY87w947gfH5kx?hash=' + params.get('hash'));
      const result = await response.json();

      if (result && result.data) {
        setClickId(result.data.CID);
        setImageUrl(result.data.SUPPORT_IMAGE);
        setTitle(result.data.TITLE);
        setTaskDescription(result.data.CTA_TEXT);
      } else {
        setAllDone(true);
      }

      setLoading(false);
  };


  const handleClick = async (isThumbsUp) => {
    setLoading(true);

    await fetch('https://goqeprn8qh.execute-api.eu-west-1.amazonaws.com/HkJvecuumafjefJJQfejBPZVVCdawm78?hash=' + params.get('hash'), {
      method: 'POST',
      body: JSON.stringify({
          cid: clickId,
          sufficient: isThumbsUp
        })
    });

    fetchData();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <Container style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#222337'
      }}>
        <CircularProgress style={{'color': 'white'}} size={30} thickness={5} />
      </Container>
    );
  }

  if (allDone) {
    return (
      <Container style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#222337'
      }}>
        <Typography style={{color: 'white'}} fontSize={25} fontWeight={'bold'} fontFamily={"'Glacial Indifference', sans-serif"}>All done! ✅</Typography>
      </Container>
    );
  }

  function isVideoFormat(url) {
    const videoFormats = [".mp4", ".webm", ".ogg", ".ogv"];
    return videoFormats.some(format => url.toLowerCase().endsWith(format));
  }

  function isImageFormat(url) {
    const imageFormats = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp", ".jfif", ".tiff"];
    return imageFormats.some(format => url.toLowerCase().endsWith(format));
  }

  return (
    <Container style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#222337'
      }}>
      <div style={{alignItems:"center", justifyContent:"center"}}>
        <div style={{textAlign: "center"}}>
          {isVideoFormat(imageUrl) ? (
            <video
              controls
              autoplay
              src={imageUrl}
              style={{ height: "70vh", objectFit: "cover", borderRadius: 15 }}
            />
          ) : isImageFormat(imageUrl) ? (
            <img
              src={imageUrl}
              alt={"Screenshot"}
              style={{ height: "70vh", objectFit: "cover", borderRadius: 15 }}
            />
          ) : (
            <iframe
              sandbox
              src={imageUrl}
              title={"Document"}
              style={{ height: "70vh", objectFit: "cover", borderRadius: 15 }}
            />
          )}
        </div>
        <div style={{alignItems:"center", justifyContent:"center", textAlign: "center"}}>
          {isVideoFormat(imageUrl) ? (
            <Typography style={{color: 'white', marginTop: 10}} fontSize={25} fontWeight={'bold'} fontFamily={"'Glacial Indifference', sans-serif"}>Does the video show sufficient evidence that the following offer has been completed?</Typography>
          ) : isImageFormat(imageUrl) ? (
            <Typography style={{color: 'white', marginTop: 10}} fontSize={25} fontWeight={'bold'} fontFamily={"'Glacial Indifference', sans-serif"}>Does the screenshot show sufficient evidence that the following offer has been completed?</Typography>
          ) : (
            <Typography style={{color: 'white', marginTop: 10}} fontSize={25} fontWeight={'bold'} fontFamily={"'Glacial Indifference', sans-serif"}>Does the document show sufficient evidence that the following offer has been completed?</Typography>
          )}
          <Typography style={{color: 'white', marginTop: 10}} fontFamily={"'Glacial Indifference', sans-serif"}>Title: {title}</Typography>
          <Typography style={{color: 'white', marginTop: 10}} fontFamily={"'Glacial Indifference', sans-serif"}>Task: {taskDescription}</Typography>
          <Button
            style={{ marginTop: 10, backgroundColor: '#75b668', color: '#ffffff', width: 100, height: 50, borderRadius: 15 }}
            startIcon={<ThumbUpIcon />}
            onClick={() => handleClick(1)}
            sx={{ mr: 2 }}
          >
            Yes
          </Button>
          <Button
            style={{ marginTop: 10, backgroundColor: '#dd5d56', color: '#ffffff', width: 100,  height: 50, borderRadius: 15 }}
            startIcon={<ThumbDownIcon />}
            onClick={() => handleClick(0)}
          >
            No
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default App;
